import React, { useState } from 'react';
import './ExperienceTimeline.css';

const experienceData = [
  {
    year: "Current",
    company: "dbt Labs",
    title: "Director of Marketing Operations",
    location: "Austin, TX",
    details: [
      "Placeholder",
      "Placeholder"
    ],
    isCurrent: true
  },
  {
    year: "July 2019 – Oct. 2021",
    company: "Rev",
    title: "Director of Integrated Marketing",
    location: "Austin, TX",
    details: [
      "Built out Demand Generation function focused on key market segments (Education & Media/Entertainment) that increased marketing sourced pipeline by 70% from H1 2020 to H2 2020",
      "Grew marketing sourced bookings from $0 in 2019 to $580k in H1 2020, and to $3.15M in H2 2020",
      "Implemented Segment Customer Data Platform (CDP) alongside our product and engineering teams",
      "Led the launch of dbt Cloud, a new product that allows users to build and deploy data transformations in a visual interface",
      "Built Rev’s first inbound funnel consisting of lead capture, scoring, and routing. Refined this funnel to world class benchmarks within a year (21% MQL -> SQL, 77% SQL->SAL, 22% SAL -> Closed Won)",
      "Built Rev’s first budget tracking system and marketing performance forecast that is now used for our annual forecasting and planning.",
      "Implemented Pardot as our marketing automation platform and aligned it with the needs of a team of 30+ users.",
      "Implemented multi-touch attribution solution (Attribution App), to more accurately calculate CAC, LTV, ROI, and CAC Payback across our self-serve and enterprise customer base."
    ],
  },
  {
    year: "Jan. 2018 – July 2019",
    company: "CareCloud",
    title: "Manager of Demand Generation",
    location: "Miami, FL",
    details: [
        "Effectively optimized our annual marketing spend in excess of $1.4MM across all channels",
        "Designed campaign direction, methodology, and execution for ABM efforts accounting for a 325% Increase in ABM generated Pipeline YoY",
        "Increased Organic Web Traffic 61% and Organic conversions by 58%",
        "Increased Lead Volume from Paid Advertising by 77% YTD while decreasing the Cost Per Acquisition (CPA) by 71%",
        "Led strategy, direction and execution of the Revenue Cycle Science Microsite through the design, development, QA and deployment phases",
        "Oversaw relationships and campaign direction with multiple channel partners",
        "Actively managed a 7 member outsourced team spanning UX, Design, Development, and Marketing functions"
      ],
  },
  {
    year: "Oct. 2017 – Jan. 2018",
    company: "McGaw.io",
    title: "Marketing Manager",
    location: "Orlando, FL",
    details: [
        "During my time at Effin Amazing I created, executed, and supported digital marketing strategies for Sales Hacker, Eventbrite, Flight Schedule Pro, Forks Over Knives, Real Thread and More"
      ],
  },
  {
    year: "Dec. 2015 – Sep. 2017",
    company: "IZEA",
    title: "Marketing Operations Manager",
    location: "Orlando, FL",
    details: [
        "Increased QoQ qualified lead generation by 17%",
        "Increased QoQ blog subscriptions by 103%",
        "Increased QoQ Organic web traffic 121%",
        "Introduced proper web event and conversion tracking allowing for more accurate reporting and ROI analysis",
        "Aligned Salesforce CRM with our business process incorporating collaborative forecasting, contract automation and improved reporting",
        "Implemented HubSpot as our marketing automation platform and aligned it with the needs of a team of 50+ users"
      ],
  },
  {
    year: "Dec. 2015 – Sep. 2017",
    company: "Metro Exhibits",
    title: "Manager of Sales and Marketing Operations",
    location: "Orlando, FL",
    details: [
        "Implemented Pardot Marketing Automation platform",
        "Administered SEM and SEO campaigns for Metro Exhibits Corporate Site and Metro Plus E-Commerce Website",
        "Put into effect Quosal CPQ for proposal automation",
        "Increased Email lead generation by nearly 40%",
        "Grew QoQ Organic traffic by 30% and Social Media Engagement by 60%",
        "Implemented and managed Salesforce CRM for a team of 15 users"
      ],
  }
];

const ExperienceTimeline = () => {
  const [expandedItems, setExpandedItems] = useState(new Set());

  const toggleDetails = (index) => {
    const newExpandedItems = new Set(expandedItems);
    if (newExpandedItems.has(index)) {
      newExpandedItems.delete(index);
    } else {
      newExpandedItems.add(index);
    }
    setExpandedItems(newExpandedItems);
  };

  return (
    <div className="experience-timeline">
      {experienceData.map((item, index) => (
        <div 
          key={index} 
          className={`timeline-item ${item.isCurrent ? 'current' : ''}`}
        >
          <div className="timeline-header">
            <div className="timeline-year">{item.year}</div>
            <div className="company">{item.company}</div>
            <div className="title">{item.title}</div>
            <div className="location">{item.location}</div>
            <button 
              className="toggle-button"
              onClick={() => toggleDetails(index)}
              aria-label={expandedItems.has(index) ? 'Close details' : 'Show details'}
            >
              {expandedItems.has(index) ? '×' : '+'}
            </button>
          </div>

          {/* Moved outside .timeline-header */}
          {item.details && expandedItems.has(index) && (
            <div className="timeline-details">
              <ul>
                {item.details.map((detail, detailIndex) => (
                  <li key={detailIndex}>{detail}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ExperienceTimeline; 