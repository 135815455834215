import React from 'react';
import './Header.css';
import headshot from '../assets/images/daniel-lambert-headshot.jpg';

const Header = () => {
  return (
    <header className="header">
      <section className="hero">
        <div className="hero-text">
          <p className="location">ATX Marketing & Operations Leader</p>
          <h1>
            I Build <span className="bold-blue">Scalable Marketing Systems</span><br />
            That Drive <span className="bold-blue">Revenue and Efficiency</span>
          </h1>
          <p className="subtext">
            I help you improve conversion, performance, and team productivity through streamlined marketing operations and data-driven strategies.
          </p>
          <div className="cta-buttons">
            <a className="btn dark" href="#experience">See my work</a>
            <a className="btn light" href="#contact">Contact Me</a>
          </div>
        </div>
        <div className="hero-image">
          <img src={headshot} alt="Daniel Lambert headshot" />
        </div>
      </section>
    </header>
  );
};

export default Header;
