import React, { useState } from 'react';
import './Portfolio.css';
import placeholderImage from '../assets/images/daniel-lambert-headshot.jpg';
import seoImage1 from '../assets/images/seo-1.png';
import seoImage2 from '../assets/images/seo-2.png';
import seoImage3 from '../assets/images/seo-3.png';
import seoImage4 from '../assets/images/seo-4.png';
import contentImage1 from '../assets/images/content-web-1.png';
import contentImage2 from '../assets/images/content-web-2.png';
import contentImage3 from '../assets/images/content-web-3.png';
import contentImage4 from '../assets/images/content-web-4.jpg';
import mopsImage1 from '../assets/images/marketing-operations-1.png';
import mopsImage2 from '../assets/images/marketing-operations-2.png';
import mopsImage3 from '../assets/images/marketing-operations-3.png';
import mopsImage4 from '../assets/images/marketing-operations-4.png';
import analyticsImage1 from '../assets/images/analytics-1.png';
import analyticsImage2 from '../assets/images/analytics-2.png';
import analyticsImage3 from '../assets/images/analytics-3.png';
import analyticsImage4 from '../assets/images/analytics-4.png';

const categories = [
  'Marketing Operations',
  'Analytics & Reporting',
  'SEO',
  'Web & Content Development',
];

const allProjects = {
  'Marketing Operations': [
    { title: 'Data Schemas', image: mopsImage1 },
    { title: 'Lead Scoring', image: mopsImage2 },
    { title: 'Intent & ABM', image: mopsImage3 },
    { title: 'AI Chat', image: mopsImage4 },
  ],
  'Analytics & Reporting': [
    { title: 'Product Analytics', image: analyticsImage1 },
    { title: 'Campaign Analytics', image: analyticsImage2 },
    { title: 'Marketing Leader Scorecard', image: analyticsImage3 },
    { title: 'Signal Based Selling', image: analyticsImage4 },
  ],
  'SEO': [
    { title: 'Influencer Marketing Post', image: seoImage1 },
    { title: 'First Position Rankings', image: seoImage2 },
    { title: 'Influencer Marketing Post #2', image: seoImage3 },
    { title: 'First Position Rankings #2', image: seoImage4 },
  ],
  'Web & Content Development': [
    { title: 'Interactive Content', image: contentImage1 },
    { title: 'Specialty Landing Pages', image: contentImage2 },
    { title: 'Customer Landing Page', image: contentImage3 },
    { title: 'Tradeshow Exhibit Landing Page', image: contentImage4 },
  ],
};

const Portfolio = () => {
  const [activeCategory, setActiveCategory] = useState('Marketing Operations');
  const [lightboxImage, setLightboxImage] = useState(null);

  return (
    <section className="portfolio-section" id="portfolio">
      <div className="portfolio-categories">
        {categories.map((category) => (
          <button
            key={category}
            className={`category-btn ${activeCategory === category ? 'active' : ''}`}
            onClick={() => setActiveCategory(category)}
          >
            {category}
          </button>
        ))}
      </div>
      <div className="portfolio-gallery">
        {allProjects[activeCategory].map((item, idx) => (
          <div
            key={idx}
            className="portfolio-card"
            onClick={() => setLightboxImage(item)}
          >
            <div className="image-wrapper">
              <img src={item.image} alt={item.title} />
            </div>
            <p>{item.title}</p>
          </div>
        ))}
      </div>

      {/* LIGHTBOX */}
      {lightboxImage && (
        <div className="lightbox-overlay" onClick={() => setLightboxImage(null)}>
          <div className="lightbox-content" onClick={(e) => e.stopPropagation()}>
            <img src={lightboxImage.image} alt={lightboxImage.title} />
            <p>{lightboxImage.title}</p>
            <button onClick={() => setLightboxImage(null)}>Close</button>
          </div>
        </div>
      )}
    </section>
  );
};

export default Portfolio; 