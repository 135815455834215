// src/Views/HomePage.js
import React from 'react';
import './HomePage.css';
import Header from './Header';
import Navigation from './Navigation';
import ExperienceTimeline from './ExperienceTimeline';
import Portfolio from './Portfolio';
import ContactSection from './ContactSection';

const HomePage = () => {
    return (
        <div className="homepage">
            <Navigation />
            <main className="main-content">
                <section id="about">
                    <Header />
                </section>
                <section id="experience" className="experience-section">
                    <ExperienceTimeline />
                    <div className="experience-cta">
                        <a className="btn dark" href="/Danny-Lambert-Resume.pdf" target="_blank" rel="noopener noreferrer">Download Resume</a>
                    </div>
                </section>
                <Portfolio />
                <ContactSection />
            </main>
        </div>
    );
};

export default HomePage;