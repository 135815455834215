import React, { useState } from 'react';
import './ContactSection.css';

const ContactSection = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Get form data
    const formData = new FormData(e.target);
    
    // Submit to Zapier
    fetch('https://hooks.zapier.com/hooks/catch/2020307/20buuhn/', {
      method: 'POST',
      body: formData
    })
    .then(response => {
      if (response.ok) {
        setIsSubmitted(true);
        e.target.reset();

        // Push custom event to GTM
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'formSubmission',
          formName: 'Contact Form',
          userEmail: formData.get('email')
        });
      } else {
        console.error('Form submission failed');
      }
    })
    .catch(error => {
      console.error('Error submitting form:', error);
    });
  };

  return (
    <section className="contact-section" id="contact">
      <h2 className="contact-title">LET'S TALK!</h2>
      
      {!isSubmitted ? (
        <form 
          className="contact-form" 
          onSubmit={handleSubmit}
        >
          <div className="form-row">
            <div className="input-group">
              <label htmlFor="name">Name</label>
              <input 
                id="name"
                name="name" 
                type="text"
                required 
              />
            </div>
            <div className="input-group">
              <label htmlFor="email">Email Address</label>
              <input 
                id="email"
                name="email" 
                type="email" 
                required 
              />
            </div>
          </div>
          <div className="input-group">
            <textarea
              id="message"
              name="message"
              placeholder="What's up?"
              required
            />
          </div>
          <button 
            type="submit" 
            className="submit-btn"
          >
            SEND
          </button>
        </form>
      ) : (
        <div className="thank-you-message">
          <h3>Thanks for reaching out!</h3>
          <p>I'll get back to you as soon as possible.</p>
          <button 
            className="submit-btn"
            onClick={() => setIsSubmitted(false)}
          >
            Send Another Message
          </button>
        </div>
      )}
    </section>
  );
};

export default ContactSection; 