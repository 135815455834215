import React, { useState } from 'react';
import './Navigation.css';

const Navigation = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="nav">
      <button 
        className="hamburger" 
        onClick={() => setIsMenuOpen(!isMenuOpen)} 
        aria-label="Toggle navigation"
      >
        <span className="bar" />
        <span className="bar" />
        <span className="bar" />
      </button>

      <div className={`nav-content ${isMenuOpen ? 'open' : ''}`}>
        <ul className="nav-links">
          <li><a href="#about">About</a></li>
          <li><a href="#experience">Experience</a></li>
          <li><a href="#portfolio">Portfolio</a></li>
        </ul>

        <div className="nav-right">
          <a href="https://www.linkedin.com/in/danielrlambert/" target="_blank" rel="noopener noreferrer">
            <img src="/icons/linkedin.svg" alt="LinkedIn" className="social-icon" />
          </a>
          <a href="https://dannylambert.substack.com/" target="_blank" rel="noopener noreferrer">
            <img src="/icons/substack.svg" alt="Substack" className="social-icon" />
          </a>
          <a href="#contact" className="btn light contact-btn">Contact Me</a>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
